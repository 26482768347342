import './css/App.css';
import * as React from 'react';
//import Sidebar from './components/Sidebar';
import logoIcon from './img/logo_med.png';
import { ThemeContext } from './theme-context';
import { IoLogoGithub } from "react-icons/io";
import { FaLinkedin } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";

function App() {
  const moonSVG = () => {
    return (
      <svg fill="none" viewBox="2 2 20 20" width="12" height="12" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" fill="currentColor" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z">
        </path>
      </svg>
    );
  };
  const sunSVG = () => {
    return (
      <svg fill="none" viewBox="3 3 18 18" width="12" height="12" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" fill="currentColor" d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z">
        </path>
      </svg>
    );
  };
  const isBrowserDefaultDark = () => window.matchMedia('(prefers-color-scheme: dark)').matches;
  const getDefaultTheme = (): string => {
    const localStorageTheme = localStorage.getItem('theme');
    const browserDefault = isBrowserDefaultDark() ? 'dark' : 'light';
    return localStorageTheme || browserDefault;
  };
  const getDefaultIcon = (): React.JSX.Element => {
    console.log(localStorage);
    const localStorageTheme = localStorage.getItem('theme');
    const browserDefault = isBrowserDefaultDark() ? 'dark' : 'light';
    if (localStorageTheme === 'light' || browserDefault === 'light') {
      return sunSVG();
    } else {
      return moonSVG();
    }
  };
  const [theme, setTheme] = React.useState(getDefaultTheme);
  const [themeIcon, setThemeIcon] = React.useState<React.JSX.Element>(getDefaultIcon);

  const handleThemeChange = () => {
    const isCurrentDark = theme === 'dark';
    setTheme(isCurrentDark ? 'light' : 'dark');
    setThemeIcon(isCurrentDark ? sunSVG : moonSVG);
    localStorage.setItem('theme', isCurrentDark ? 'light' : 'dark');
  };

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <div className={`theme-${theme}`}>
        <div className="App" >
          <header className="App-header" >
            {/* <Sidebar /> */}
            <div className='App-logo'>
              <img className="main_logo" src={logoIcon} alt="" /><br />
              Designs by Chace
            </div>
            <div className="social_buttons">

              <a href="https://www.linkedin.com/in/chaceprochazka">
                <FaLinkedin />
              </a>

              <a href="https://github.com/chacepro">
                <IoLogoGithub />
              </a>

              <a href="mailto:chace@designsbychace.com">
                <MdOutlineMailOutline />
              </a>
            </div>
            <div className="theme-toggle">
              {themeIcon}
              <input
                title="Change Theme"
                type="checkbox"
                className="theme-toggle"
                id="switch"
                onClick={handleThemeChange}>
              </input>
              <label htmlFor="switch"></label>
            </div>
          </header>
        </div>
      </div>
    </ThemeContext.Provider>
  );
}

export default App;
